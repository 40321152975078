#FormContact.contact-form {
    width: 100%; 
    margin: 0 auto;
    padding: 20px;
    margin-top: 50px;
}

#FormContact .form-row {
    display: flex;
    gap: 10px; 
    margin-bottom: 15px;
}

#FormContact .form-group {
    flex: 1; 
    align-items: start;
}
#PhoneNumber{
    direction: rtl;
}
#FormContact .form-group input,
#FormContact .form-group textarea {
    width: 100%; 
    padding: 10px;
    border: none;
    border-radius: 4px;
    box-shadow: 1px 2px 5px 2px rgba(0, 0, 0, 0.1);
    font-size: 16px; 
}

#FormContact .form-submit {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#FormContact button {
    padding: 10px 15px;
    background-color: rgba(76, 175, 79, 1);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: .3s;
}

#FormContact button:hover {
    background-color:rgb(38, 124, 41);
}

#FormContact .arrow {
    font-size: 20px;
    margin-left: 10px;
}
