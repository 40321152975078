.add-account-container {
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
}

/* Profile Picture Container */
.profile-picture-container {
    margin-bottom: 20px;
}

.profile-picture {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: rgba(76, 175, 79, 1);
    color: rgba(255, 255, 255, 0.74);
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    cursor: pointer;
}

.profile-picture input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Form Fields */
.form-fields {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-group {
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.form-group div {
    display: flex;
    flex-direction: column !important;
    width: 100%;
}

.form-group label {
    flex: 1;
    margin-bottom: 5px; 
    font-weight: bold;
    font-size: 14px;
}

.form-group input, .form-group select {
    flex: 2;
    padding: 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-family: 'Cairo', sans-serif;

}

select {
    appearance: none; 
    background-color: #ffffff;
    cursor: pointer;
    padding-right: 20px;
    position: relative;
}

.add-account-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--main-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    font-family: 'Cairo', sans-serif;
    margin-top: 50px;
}

.add-account-button:hover {
    background-color: var(--hover-color);
}

@media (max-width: 980px) {
    .form-group {
       flex-direction: column;
    }
}  