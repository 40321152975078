.SubmitApplication {
    position: relative;
    width: 100%;
    height: 70vh; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 83px;
  }

  .SubmitApplication .image-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(31, 31, 31, 0.85);
  }
  
  .SubmitApplication .text-content {
    z-index: 2; 
    color: white;
    padding: 40px 140px;
    margin-top: 70px;
    width: 50%;
  }
  .SubmitApplication .welcome-text {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
    margin-right: 30px;
    position: relative;
  }
  
  .SubmitApplication .welcome-text::before {
    content: "";
    position: absolute;
    height: 60%;
    top: 55%;
    transform: translateY(-50%);
    width: 0;
    right: -30px;
    border: 5px solid rgba(76, 175, 79, 1);
  }

  .SubmitApplication .description {
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.85);
    margin-bottom: 40px;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .SubmitApplication .welcome-text {
      font-size: 2rem;
    }
    .SubmitApplication .text-content{
      width: 100% !important;
      padding: 40px 40px !important;

    }
    .SubmitApplication .description {
      font-size: 1rem;
    }
  }
  