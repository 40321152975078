.Login {
    display: flex;
    height: 100vh;
    align-items: center;
}

.Login .container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.Login .image-container {
    width: 50%;
    position: relative;
}
.Login .image-container .background-image{
    width: 100%;
    height: 99vh;
}
.Login .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
}

.Login .logo {
    width: 150px;
    height: auto;
}

.Login .form-container {
    width: 50%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Login .form-container h2 {
    font-size: 34px;
    color: #333;
    margin-bottom: 20px;
}

.Login form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Login form label {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: right;
}

.Login form input {
    padding: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.Login form button {
    padding: 10px;
    font-size: 18px;
    background-color: rgba(95, 182, 69, 1); 
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
}

.Login form button:hover {
    background-color: rgba(85, 160, 60, 1); 
}

.Login .logo-left {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 100px;
    height: auto;
}

.Login .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.Login .form-container{
    background-color: white !important;

}
.error-message{
    color: rgba(255, 24, 53, 1);
    font-weight: 500;
    margin-top: 20px;
}
@media (max-width: 768px) {
    .Login {
        flex-direction: column; 
        height: auto;
    }
    .Login .image-container {
        width: 100%;
        height: 100vh; 
    }
    .Login .form-container {
        width: 80%; 
        padding: 20px;
        position: absolute;
        top: 50%; 
        left: 50%;
        transform: translate(-50%, -50%); 
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        align-items: start;
    }

    .Login form {
        margin: 0 auto;
    }
    .Login .form-container h2{
        font-size: 20px;
    }
}